<template lang="pug">
v-card(v-if="Object.keys(sailorDocument).length").pa-4
  v-card-title.d-flex.justify-space-between.align-center
    div {{ $t(`${Object.keys(sailorDocument.behavior)[0]}-${type}`).toUpperCase() }}
      span(v-if="checkAccess('backOffice')").ml-1 (ID: {{ sailorDocument.id }})
    ControlButtons(:controlButtons="controlButtons")
  v-card-text
    v-divider
    Info(v-if="sailorDocument.behavior.viewInfoBlock" :statement="sailorDocument" :comments="comments")
    FormEdit(v-else-if="sailorDocument.behavior.viewEditBlock" :statement="sailorDocument")
    EditStatus(v-else-if="sailorDocument.behavior.viewEditStatusBlock" :statement="sailorDocument")
    ViewPhotoList(
      v-else-if="sailorDocument.behavior?.viewFilesBlock"
      :files="sailorDocument.files"
      :sailorDocument="sailorDocument"
      :documentType="type"
      :deleteFile="removeFileQualificationStatement"
      :editFile="updateFileQualificationStatement"
      isShowEdit
      )
</template>

<script>
import { viewDetailedComponent, back, deleteConfirmation, copyToClipboard } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapActions, mapGetters } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  name: 'SailorQualificationStatementDocument',
  components: {
    Info: () => import('@/views/Sailor/SailorQualificationStatement/Info.vue'),
    FormEdit: () => import('@/views/Sailor/SailorQualificationStatement/Form.vue'),
    EditStatus: () => import('@/views/Sailor/SailorQualificationStatement/EditStatus.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewFile/ViewFile.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons')
  },
  data () {
    return {
      type: 'qualificationStatement',
      comments: [],
      viewDetailedComponent,
      deleteConfirmation,
      checkAccess,
      back,
      controlButtons: [
        {
          id: 8,
          name: 'mdi-content-copy',
          action: () => copyToClipboard(location.href),
          color: '#42627e',
          tooltip: 'documentIconControl.copyLink',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewInfoBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditBlock'),
          checkAccess: () => checkAccess('qualificationStatement', 'edit', this.sailorDocument),
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 6,
          name: 'mdi-sync',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditStatusBlock'),
          checkAccess: () => checkAccess('qualificationStatement', 'editStatus', this.sailorDocument),
          color: '#42627e',
          tooltip: 'documentIconControl.editStatus',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewFilesBlock'),
          checkAccess: () => checkAccess('qualificationStatement', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.files',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 4,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteDocument(),
          checkAccess: () => checkAccess('qualificationStatement', 'delete', this.sailorDocument),
          color: '#F73E07',
          tooltip: 'documentIconControl.delete'
        },
        {
          id: 5,
          name: 'mdi-close',
          action: () => this.$router.push({ name: 'qualification-statements', params: { id: this.id } }),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['sailorDocumentByID']),
    ...mapState({
      id: state => state.sailor.sailorId,
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      sailorDocument: state => state.sailor.qualificationStatementById
    })
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['removeQualificationStatementById', 'getQualificationStatementById', 'getSuccessProtocolSQC',
      'removeFileQualificationStatement', 'updateFileQualificationStatement']),
    async getData () {
      await this.getQualificationStatementById({ ...this.$route.params })
    },
    deleteDocument () {
      deleteConfirmation(this).then(async confirmation => {
        if (confirmation) {
          const response = await this.removeQualificationStatementById({ ...this.$route.params })
          if (SUCCESS_CODE.includes(response?.code)) {
            this.$notification.success('deletedDocument')
            back('qualification-statements')
          } else this.$notification.error('cantDeleteDoc')
        }
      })
    }
  }
}

</script>
